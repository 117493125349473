html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;

  /*Prevents input focus scroll*/
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.hydrated {
  visibility: inherit;
}

#ohp-environment-tag {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: yellow;
  z-index: 99999;
  font-weight: bold;
  padding: 7px 10px;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  @media screen and (max-width: 1140px) {
    margin-top: 60px;
  }
}

.ptr--ptr {
  position: relative;
  display: flex;
  z-index: 99999;
  background-color: #f0f0f2;
}
